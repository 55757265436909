import React, { useState, useContext } from "react";
import "./Login.css";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import notifications from "../../notifications/notification";
function Login() {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [linkSent, setLinkSent] = useState(null);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${process.env.REACT_APP_SHIPNOW_URL}web-user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            notifications("Error", "Login Failed");
            let errorMessage = "Authentication Failed";
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        console.log(data);
        if (data?.data) {
          notifications("Success", data?.message);
          const expiresIn = "3600"; // 3600 Seconds = 60 Min
          const expirationTime = new Date(
            new Date().getTime() + +expiresIn * 1000
          );
          authCtx.login(data.data.access_token, expirationTime.toISOString());
        } else {
          notifications("Error", data?.message);
        }
        // history.replace('/')
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleResetLink = async (e) => {
    e.preventDefault();
    let data = {
      email: email,
    };
    console.log("What we send", data);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SHIPNOW_URL}corporate/forget/password`,
      data: data,
    };
    setLoading(true);
    await axios
      .request(options)
      .then(function (response) {
        console.log(response);
        setLinkSent(
          "You will receive reset link shortly if you were registered user in the database"
        );
        notifications("API response", response.data?.message);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
        notifications("Error", "Something Went wrong");
      });
  };

  return (
    <div className="form_background">
      <div className="login">
        <div className="wrap">
          {/* TOGGLE */}
          <div id="toggle-wrap">
            <div id="toggle-terms">
              <div id="cross">
                <span />
                <span />
              </div>
            </div>
          </div>
          {/* SLIDER */}
          <div className="content">
            {/* LOGO */}
            <div className="logo">
              <a href="#">{/* <img src={business_logo} alt /> */}</a>
            </div>
            {/* SLIDESHOW */}
            <div id="slideshow">
              <div className="one">
                <h2>
                  <span>SHIPNOW</span>
                </h2>
                <p>Better Than Carrier</p>
              </div>
            </div>
          </div>
          {/* LOGIN FORM */}
          <div className="user">
            {/* ACTIONS
              <div class="actions">
                  <a class="help" href="#signup-tab-content">Sign Up</a><a class="faq" href="#login-tab-content">Login</a>
              </div>
              */}
            <div className="form-wrap">
              {/* TABS */}
              <div className="tabs">
                <h3 className="login-tab">
                  <a className="log-in active">
                    <span>
                      TMS Login
                      <span />
                    </span>
                  </a>
                </h3>
              </div>
              {/* TABS CONTENT */}
              <div className="tabs-content">
                {/* TABS CONTENT LOGIN */}
                <div id="login-tab-content" className="active">
                  {(forgotPassword === true && (
                    <form className="login-form" onSubmit={handleResetLink}>
                      <input
                        type="text"
                        className="input"
                        id="forgot_pass"
                        required
                        autoComplete="off"
                        placeholder="Please Enter Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        onClick={() => {
                          setForgotPassword(false);
                          setLinkSent(null);
                        }}
                        className="normal-button"
                        type="button"
                      >
                        Back to Login
                      </button>
                      {/* <input
                      type="checkbox"
                      className="checkbox"
                      id="remember_me"
                    />
                    <label htmlFor="remember_me">Remember me</label> */}
                      {(linkSent === null && (
                        <>
                          {loading ? (
                            <div
                              class="spinner-border spinner-border-sm mr-1 text-center"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <input
                              type="submit"
                              className="button"
                              defaultValue="Login"
                              value="Send Reset Link"
                            />
                          )}
                        </>
                      )) ||
                        (linkSent !== null && <p className="message-text">{linkSent}</p>)}
                    </form>
                  )) ||
                    (forgotPassword !== true && (
                      <form className="login-form" onSubmit={handleSubmit}>
                        <input
                          type="text"
                          className="input"
                          id="user_login"
                          required
                          autoComplete="off"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                          type="password"
                          className="input"
                          id="user_pass"
                          required
                          autoComplete="off"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          onClick={() => setForgotPassword(true)}
                          className="normal-button"
                          type="button"
                        >
                          Forgot Password?
                        </button>
                        {/* <input
                      type="checkbox"
                      className="checkbox"
                      id="remember_me"
                    />
                    <label htmlFor="remember_me">Remember me</label> */}
                        {loading ? (
                          <div
                            class="spinner-border spinner-border-sm mr-1 text-center"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <input
                            type="submit"
                            className="button"
                            value="Login"
                          />
                        )}
                      </form>
                    ))}
                  <div className="help-action"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
