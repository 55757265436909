import {  notification } from 'antd';

const openNotification = (msg,details) => {
  notification.info({
    message: msg,
    description:details,
    placement:"bottomRight",
    duration:3
  });
};
export default openNotification
