import React, { useContext } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "antd/dist/antd.css";
import Login from "./views/pages/login/Login";
import AuthContext from "./store/auth-context";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const TrackingOrder = React.lazy(() => import("./views/pages/map"));

function App() {
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <HashRouter forceRefresh={true}>
      <React.Suspense fallback={loading}>
        <Switch>
          {!isLoggedIn && (
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
          )}
          {isLoggedIn && (
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          )}

          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
